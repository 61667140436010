import hRequest from '../index'
import { IAccount, ILoginResult } from './type'
import { IDataType } from '../types'

enum LoginApi {
  AccountApi = '/login',
  LoginUserInfo = '/users/',
  UserMenus = '/role/',
  CodeApi = '/code'
}

export function accountLoginRequest(account: IAccount) {
  return hRequest.post<IDataType<ILoginResult>>({
    url: LoginApi.AccountApi,
    data: account,
    showLoading: true
  })
}

export function requestUserInfoById(id: number) {
  return hRequest.get<IDataType>({
    url: LoginApi.LoginUserInfo + id,
    showLoading: true
  })
}

export function requestUserMenusByRoleId(id: number) {
  return hRequest.get<IDataType>({
    url: LoginApi.UserMenus + id + '/menu',
    showLoading: true
  })
}

export function requestCode() {
  return hRequest.get<IDataType>({
    url: LoginApi.CodeApi,
    showLoading: true
  })
}
