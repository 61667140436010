import { IRootState } from '@/store/type'
import { Module } from 'vuex'
import router from '@/router'
import { ISystemState, IReceiptState } from './types'
import {
  getPageListDate,
  deletePageData,
  createPageData,
  editPageData,
  updatePassWord,
  updateReceiptState,
  getMemoryList
} from '@/service/main/system/system'
import LocalCache from '@/utils/cache'

const systemModule: Module<ISystemState, IRootState> = {
  namespaced: true,
  state() {
    return {
      usersList: [],
      usersCount: 0,
      roleList: [],
      roleCount: 0,
      menuList: [],
      menuCount: 0,
      orderList: [],
      orderCount: 0,
      companyList: [],
      companyCount: 0,
      receiptList: [],
      receiptCount: 0,
      notrecoveryList: [],
      notrecoveryCount: 0,
      recoveryList: [],
      recoveryCount: 0,
      memoryList: [],
      queryInfo: {},
      pageInfo: {}
    }
  },
  mutations: {
    changeUsersList(state, userslist: any[]) {
      state.usersList = userslist
    },
    changeUsersCount(state, usersCount: number) {
      state.usersCount = usersCount
    },
    changeRoleList(state, rolelist: any[]) {
      state.roleList = rolelist
    },
    changeRoleCount(state, roleCount: number) {
      state.roleCount = roleCount
    },
    changeMenuList(state, menulist: any[]) {
      state.menuList = menulist
    },
    changeMenuCount(state, menuCount: number) {
      state.menuCount = menuCount
    },
    changeOrderList(state, orderlist: any[]) {
      state.orderList = orderlist
    },
    changeOrderCount(state, orderCount: number) {
      state.orderCount = orderCount
    },
    changeCompanyList(state, companylist: any[]) {
      state.companyList = companylist
    },
    changeCompanyCount(state, companyCount: number) {
      state.companyCount = companyCount
    },
    changeReceiptList(state, receiptlist: any[]) {
      state.receiptList = receiptlist
    },
    changeReceiptCount(state, receiptCount: number) {
      state.receiptCount = receiptCount
    },
    changeNotrecoveryList(state, notrecoverylist: any[]) {
      state.notrecoveryList = notrecoverylist
    },
    changeNotrecoveryCount(state, notrecoveryCount: number) {
      state.notrecoveryCount = notrecoveryCount
    },
    changeRecoveryList(state, recoverylist: any[]) {
      state.recoveryList = recoverylist
    },
    changeRecoveryCount(state, recoveryCount: number) {
      state.recoveryCount = recoveryCount
    },
    changeMemoryList(state, memoryList: any[]) {
      state.memoryList = memoryList
    },
    changeQueryInfo(state, queryInfo: any[]) {
      state.queryInfo = queryInfo
    },
    changePageInfo(state, pageInfo: number) {
      state.pageInfo = pageInfo
    }
  },
  getters: {
    pageListDate(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}Count`]
      }
    }
  },
  actions: {
    async getPageListAction({ commit }, payload: any) {
      const pageName = payload.pageName
      const pageUrl = `/${pageName}/list`

      // 页面发送请求
      const pageResult = await getPageListDate(pageUrl, payload.queryInfo)
      if (pageResult.code === -200) {
        LocalCache.deleteCache('token')
        router.push('/login')
      }

      if (pageResult.data) {
        const { list, totalCount } = pageResult.data
        const changePageName =
          pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
        const memory = await getMemoryList('/memory/list')
        commit(`changeMemoryList`, memory.data)
        commit(`change${changePageName}List`, list)
        commit(`change${changePageName}Count`, totalCount)
        commit(`changeQueryInfo`, payload.queryInfo)
        commit(`changePageInfo`, payload.pageInfo)
      }

      return pageResult
    },

    async deletePageDataAction({ dispatch }, payload: any) {
      const { pageName, id, query, pageInfo } = payload
      const pageUrl = `/${pageName}/${id}`

      const result = await deletePageData(pageUrl)

      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          offset: (pageInfo.value.currentPage - 1) * pageInfo.value.pageSize,
          size: pageInfo.value.pageSize,
          ...query
        },
        pageInfo
      })

      return result
    },

    async createPageDataAction({ dispatch }, payload: any) {
      // 创建数据的请求
      const { pageName, newData, query, pageInfo } = payload
      const pageUrl = `/${pageName}`

      const result = await createPageData(pageUrl, newData)

      // 请求最新的数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          offset: (pageInfo.currentPage - 1) * pageInfo.pageSize,
          size: pageInfo.pageSize,
          ...query
        },
        pageInfo
      })
      return result
    },

    async editPageDataAction({ dispatch }, payload: any) {
      // 修改数据的请求
      const { pageName, editData, id, query, pageInfo } = payload

      const pageUrl = `/${pageName}/${id}`
      const result = await editPageData(pageUrl, editData)

      // 请求最新的数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          offset: (pageInfo.currentPage - 1) * pageInfo.pageSize,
          size: pageInfo.pageSize,
          ...query
        },
        pageInfo
      })
      return result
    },

    async updatePassWord(NULL, payload: any) {
      const { id, password } = payload
      const pageUrl = `/users/${id}/password`
      const result = await updatePassWord(pageUrl, password)
      return result
    },

    async updateReceiptState({ dispatch }, payload: any) {
      // console.log(payload.ReceiptData)
      const { ReceiptData, state, pageInfoVal, queryInfoVal, pageName } =
        payload
      const pageInfo = pageInfoVal
      const receiptState: IReceiptState = {
        recoverystate: '',
        issuestate: '',
        poststate: ''
      }
      let result: any

      // 判断是哪个按钮
      if (state === 'recovery') {
        receiptState.recoverystate = '已回收'
      } else if (state === 'receive') {
        receiptState.issuestate = '已接收'
      } else {
        receiptState.poststate = '已寄出'
      }
      for (let i = 0; i < ReceiptData.length; i++) {
        const id = ReceiptData[i].id
        const pageUrl = `/receipt/${id}`
        result = await updateReceiptState(pageUrl, receiptState)
      }

      // 请求最新的数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          offset: (pageInfo.currentPage - 1) * pageInfo.pageSize,
          size: pageInfo.pageSize,
          ...queryInfoVal
        },
        pageInfo
      })
      return result
    },

    async getMemoryList() {
      const pageUrl = '/memory/list'
      const result = await getMemoryList(pageUrl)
      // console.log(result)
      return result
    }
  }
}

export default systemModule
