import YhRequest from '../../index'
import { IDataType } from '../../types'

export function getPageListDate(url: string, queryInfo: any) {
  return YhRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

export function deletePageData(url: string) {
  return YhRequest.delete<IDataType>({
    url: url
  })
}

export function createPageData(url: string, newData: any) {
  return YhRequest.post<IDataType>({
    url: url,
    data: newData
  })
}

export function editPageData(url: string, editData: any) {
  return YhRequest.patch<IDataType>({
    url: url,
    data: editData
  })
}

export function updatePassWord(url: string, passwrod: string) {
  return YhRequest.patch<IDataType>({
    url: url,
    data: passwrod
  })
}

export function updateReceiptState(url: string, state: any) {
  return YhRequest.patch<IDataType>({
    url: url,
    data: state
  })
}

export function getMemoryList(url: string) {
  return YhRequest.post<IDataType>({
    url: url
  })
}
