import YhRequest from '../../index'
import { IDataType } from '../../types'

enum AnalysisApi {
  HeaderApi = '/chart/headerList'
}

export function getHeaderList() {
  return YhRequest.get<IDataType>({
    url: AnalysisApi.HeaderApi
  })
}
