import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
dayjs.extend(utc)

export function formatUtcString(
  utcString: string,
  format: string = DATE_TIME_FORMAT
) {
  const time = dayjs.utc(utcString)

  return dayjs(time).add(8, 'hour').format(format)
}

export function formatTime(time: string) {
  return dayjs(time).valueOf
}
