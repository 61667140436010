import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState, IStoreType } from './type'
import login from './login/login'
import system from './main/system/system'
import analysis from './main/analysis/analysis'

import { getPageListDate } from '@/service/main/system/system'
import { requestCode } from '@/service/login/login'

const store = createStore<IRootState>({
  state() {
    return {
      entireRole: [],
      entireCompany: []
    }
  },
  mutations: {
    changeEnireRole(state, list) {
      state.entireRole = list
    },
    changeEnireCompany(state, list) {
      state.entireCompany = list
    }
  },
  getters: {},
  actions: {
    async getInitialDataAction({ commit }) {
      // 请求角色和发货单位数据
      const roleResult = await getPageListDate('/role/list', {
        offset: 0,
        size: 1000
      })

      const companyResult = await getPageListDate('/company/list', {
        offset: 0,
        size: 1000
      })
      const { list: roleList } = roleResult.data
      const { list: companyList } = companyResult.data

      // 保存数据
      commit('changeEnireRole', roleList)
      commit('changeEnireCompany', companyList)
    },

    async requestCode() {
      const codeSvg = await requestCode()
      return codeSvg.data
    }
  },
  modules: {
    login,
    system,
    analysis
  }
})

export function setupStore() {
  store.dispatch('login/loadLocalLogin')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
