import HRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import LocalCache from '@/utils/cache'

// 如果有不同的请求地址，就给它导出两个
const hRequest = new HRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = LocalCache.getCache('token')
      if (token) {
        config.headers.Authorization = token
      }
      return config
    }
  }
})

export default hRequest
