import axios from 'axios'
import type { AxiosInstance } from 'axios'
import type { HRequestInterceptors, HRequestConfig } from './type'
import NProgress from 'nprogress'

const DEFAULT_LOADING = true

class HRequest {
  instance: AxiosInstance
  interceptors?: HRequestInterceptors
  showLoading: boolean
  constructor(config: HRequestConfig) {
    // 创建axios实例
    this.instance = axios.create(config)
    // 保存基本信息
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEFAULT_LOADING

    // 使用拦截器
    // 请求拦截
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    // 响应拦截
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )

    // 添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        NProgress.set(0.7)
        return config
      },
      (err) => {
        return err
      }
    )

    this.instance.interceptors.response.use(
      (res) => {
        // 请求响应成功，移除掉NProgress
        NProgress.done()
        const data = res.data
        return data
      },
      (err) => {
        // 请求响应失败，也移除掉NProgress
        NProgress.done()
        return err
      }
    )
  }

  request<T = any>(config: HRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 单独请求对config的处理
      // 给单独的请求加单独的拦截器
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }
      // 如果单独的请求传过来的showloading为false，则不显示loading
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      }
      // 单独请求对数据的处理
      this.instance.request<any, T>(config).then(
        (res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 将showloading设置为true，不会影响到下一个请求
          this.showLoading = DEFAULT_LOADING
          resolve(res)
        },
        (err) => {
          // 将showloading设置为true，不会影响到下一个请求
          this.showLoading = DEFAULT_LOADING
          reject(err)
          return err
        }
      )
    })
  }

  get<T = any>(config: HRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T = any>(config: HRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T = any>(config: HRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T = any>(config: HRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default HRequest
