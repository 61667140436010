import { IRootState } from '@/store/type'
import { Module } from 'vuex'
import { IAnalysisState } from './types'
import { getHeaderList } from '@/service/main/analysis/analysis'

const analysisModule: Module<IAnalysisState, IRootState> = {
  namespaced: true,
  state() {
    return {
      topPanelDatas: []
    }
  },
  mutations: {
    changeTopPanel(state, list) {
      state.topPanelDatas = list
    }
  },
  actions: {
    async getHeaderListAction({ commit }) {
      const result = await getHeaderList()
      commit('changeTopPanel', result.data)
    }
  }
}

export default analysisModule
