import { Module } from 'vuex'
import {
  accountLoginRequest,
  requestUserInfoById,
  requestUserMenusByRoleId
} from '@/service/login/login'
import LocalCache from '@/utils/cache'
import router from '@/router'
import { mapMenusToRoutes } from '@/utils/map-menus'

import type { ILoginState } from './type'
import type { IRootState } from '../type'
import type { IAccount } from '@/service/login/type'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      userInfo: {},
      userMenus: []
    }
  },
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenu(state, userMenus: any) {
      state.userMenus = userMenus

      // 将userMenus加入到routes
      const routes = mapMenusToRoutes(userMenus)

      // 将routes加入到main.children
      routes.forEach((route) => {
        router.addRoute('main', route)
      })
    }
  },
  actions: {
    async accountLoginAction({ commit, dispatch }, payload: IAccount) {
      // 实现登录
      const loginResult = await accountLoginRequest(payload)
      console.log(loginResult)
      if (loginResult.code === 0) {
        const { id, token } = loginResult.data
        console.log(token)

        commit('changeToken', token)
        LocalCache.setCache('token', token)

        // 发送初始化请求(完整的role/company)
        dispatch('getInitialDataAction', null, { root: true })

        // 请求用户信息
        const userInfoResult = await requestUserInfoById(id)
        const userInfo: any = userInfoResult
        console.log(userInfo)

        if (userInfo.code === 0) {
          commit('changeUserInfo', userInfo.data)
          LocalCache.setCache('userInfo', userInfo.data)

          // 请求用户对应的菜单
          const userMenusResult = await requestUserMenusByRoleId(
            userInfo.data.role.id
          )
          console.log(userMenusResult)

          const userMenus = userMenusResult.data
          console.log(userMenus)
          if (userMenus !== undefined && userMenus.length) {
            commit('changeUserMenu', userMenus)
            LocalCache.setCache('userMenus', userMenus)

            // 跳转首页
            router.push('/main')
          } else {
            LocalCache.deleteCache('token')
            LocalCache.deleteCache('userInfo')
            const loginResult = {
              code: -200,
              data: '请求菜单失败，请重新登陆！'
            }
            return loginResult
          }
          return loginResult
        } else {
          LocalCache.deleteCache('token')
          LocalCache.deleteCache('userInfo')
          const loginResult = {
            code: -200,
            data: '请求用户失败，请重新登陆！'
          }
          return loginResult
        }
      } else {
        return loginResult
      }
    },
    loadLocalLogin({ commit, dispatch }) {
      const token = LocalCache.getCache('token')
      if (token) {
        commit('changeToken', token)

        // 发送初始化请求(完整的role/company)
        dispatch('getInitialDataAction', null, { root: true })
      }
      const userInfo = LocalCache.getCache('userInfo')
      if (token) {
        commit('changeUserInfo', userInfo)
      }
      const userMenus = LocalCache.getCache('userMenus')
      if (token) {
        commit('changeUserMenu', userMenus)
      }
    }
  },
  getters: {}
}

export default loginModule
