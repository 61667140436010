let BASE_URL = ''
const TIME_OUT = 10000

// 开发环境：development 生产环境：prodection 测试环境：test
if (process.env.NODE_ENV === 'development') {
  // BASE_URL = '/api'
  BASE_URL = '/api'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = '/api'
}

export { BASE_URL, TIME_OUT }
